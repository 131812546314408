import "./styles/app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { Toaster } from "react-hot-toast";
import { Protected, Public, Manager, Admin } from "./middleware/route";
import React, { lazy, Suspense } from "react";
import Loading from "./components/Loading";
import { ConfigProvider } from "antd";
import PasswordRecoveryDetail from "./pages/PasswordRecoveryDetail";

const Home = lazy(() => import("./pages/Home"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Appointments = lazy(() => import("./pages/Appointments"));
const Doctors = lazy(() => import("./pages/Doctors"));
const Advises = lazy(() => import("./pages/Advises"));
const Introduce = lazy(() => import("./pages/Introduce"));
const News = lazy(() => import("./pages/News"));
const NewsDetail = lazy(() => import("./pages/NewsDetail"));
const Facilities = lazy(() => import("./pages/Facilities"));
const Services = lazy(() => import("./pages/Services"));
const Profile = lazy(() => import("./pages/Profile"));
const PaymentMedical = lazy(() => import("./pages/PaymentMedical"));
const RecordMed = lazy(() => import("./pages/RecordMed"));
const Notifications = lazy(() => import("./pages/Notifications"));
const ApplyDoctor = lazy(() => import("./pages/ApplyDoctor"));
const Error = lazy(() => import("./pages/Error"));
const Policy = lazy(() => import("./pages/Policy"));
const PasswordRecovery = lazy(() => import("./pages/PasswordRecovery"));
const OTPAuthentication = lazy(() => import("./pages/OTPAuthentication"));

const CONFIG_ANTD = {
    token: {
        colorPrimary: "#0077ff",
        colorInfo: "#0077ff",
    },
};

function App() {
    return (
        <ConfigProvider theme={CONFIG_ANTD}>
            <Router>
                {/* <Layout> */}
                <Toaster />
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/register"
                            element={
                                <Public>
                                    <Register />
                                </Public>
                            }
                        />
                        <Route
                            path="/passwordrecovery"
                            element={
                                <Public>
                                    <PasswordRecovery />
                                </Public>
                            }
                        />

                        <Route
                            path="/otp/:id"
                            element={
                                <Public>
                                    <OTPAuthentication />
                                </Public>
                            }
                        />
                        <Route path="passwordrecovery/:id" element={<PasswordRecoveryDetail />} />
                        <Route
                            path="/policy"
                            element={
                                <Public>
                                    <Policy />
                                </Public>
                            }
                        />
                        <Route path="/" element={<Home />} />
                        <Route path="/doctors" element={<Doctors />} />
                        <Route
                            path="/appointments"
                            element={
                                <Protected>
                                    <Appointments />
                                </Protected>
                            }
                        />
                        <Route
                            path="/notifications"
                            element={
                                <Protected>
                                    <Notifications />
                                </Protected>
                            }
                        />
                        <Route
                            path="/applyfordoctor"
                            element={
                                <Protected>
                                    <ApplyDoctor />
                                </Protected>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <Protected>
                                    <Profile />
                                </Protected>
                            }
                        />
                        <Route
                            path="/record"
                            element={
                                <Protected>
                                    <RecordMed />
                                </Protected>
                            }
                        />
                        <Route
                            path="/payment-medical"
                            element={
                                <Public>
                                    <PaymentMedical />
                                </Public>
                            }
                        />
                        <Route
                            path="/facilities"
                            element={
                                <Public>
                                    <Facilities />
                                </Public>
                            }
                        />
                        <Route
                            path="/services"
                            element={
                                <Public>
                                    <Services />
                                </Public>
                            }
                        />
                        <Route
                            path="/advise"
                            element={
                                <Public>
                                    <Advises />
                                </Public>
                            }
                        />
                        <Route
                            path="/news"
                            element={
                                <Public>
                                    <News />
                                </Public>
                            }
                        />
                        <Route
                            path="/news/:slug"
                            element={
                                <Public>
                                    <NewsDetail />
                                </Public>
                            }
                        />
                        <Route
                            path="/introduce"
                            element={
                                <Public>
                                    <Introduce />
                                </Public>
                            }
                        />
                        <Route
                            path="/dashboard/users"
                            element={
                                <Admin>
                                    <Dashboard type={"users"} />
                                </Admin>
                            }
                        />

                        <Route
                            path="/dashboard/doctors"
                            element={
                                <Admin>
                                    <Dashboard type={"doctors"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/reports"
                            element={
                                <Admin>
                                    <Dashboard type={"reports"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/info"
                            element={
                                <Admin>
                                    <Dashboard type={"info"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/appointments"
                            element={
                                <Admin>
                                    <Dashboard type={"appointments"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/services"
                            element={
                                <Admin>
                                    <Dashboard type={"services"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/facilities"
                            element={
                                <Admin>
                                    <Dashboard type={"facilities"} />
                                </Admin>
                            }
                        />

                        <Route
                            path="/dashboard/decentralization"
                            element={
                                <Admin>
                                    <Dashboard type={"decentralization"} />
                                </Admin>
                            }
                        />

                        <Route
                            path="/dashboard/decentralization/:id"
                            element={
                                <Admin>
                                    <Dashboard type={"decentralizationDetail"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/news"
                            element={
                                <Admin>
                                    <Dashboard type={"news"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/departments"
                            element={
                                <Admin>
                                    <Dashboard type={"departments"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/cooperates"
                            element={
                                <Admin>
                                    <Dashboard type={"cooperates"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/appointmentremind"
                            element={
                                <Admin>
                                    <Dashboard type={"appointmentremind"} />
                                </Admin>
                            }
                        />
                        <Route
                            path="/dashboard/notification"
                            element={
                                <Admin>
                                    <Dashboard type={"notification"} />
                                </Admin>
                            }
                        />
                        <Route path="*" element={<Error />} />
                    </Routes>
                </Suspense>
                {/* </Layout> */}
            </Router>
        </ConfigProvider>
    );
}

export default App;
