import React, { useCallback, useEffect, useMemo, useState } from "react";
import Navbar from "../components/Navbar";
import { Button, Form, Input, message } from "antd";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";

const PasswordRecoveryDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState("start");
    const deadline = Date.now() + 1000 * 60;
    const [loading, setLoading] = useState(true);
    const [decodedId, setDecodedId] = useState(null);

    useEffect(() => {
        try {
            const decoded = jwtDecode(id);
            setDecodedId(decoded);
            handleDecodeID(decoded);
        } catch (error) {
            navigate("/not-found");
        } finally {
            setLoading(false);
        }
    }, [id, navigate]);

    const handleDecodeID = (decodedId) => {
        console.log(decodedId, "decodedIDdddddd");
        if (decodedId !== null) {
            try {
                if (!decodedId?.ID) {
                    navigate("/not-found");
                } else {
                    const timezone = "Asia/Ho_Chi_Minh";
                    const idTime = moment.utc(decodedId?.time); // Chuyển đổi thời gian từ UTC
                    const localTime = idTime.tz(timezone);
                    const currentTime = moment();
                    const timeDiff = currentTime.diff(idTime, "seconds");
                    console.log(timeDiff, "timeDifff");
                    if (timeDiff > 300) {
                        setStatus("timeout");
                    }
                }
            } catch (error) {
                navigate("/not-found");
            } finally {
                setLoading(false);
            }
        }
    };

    if (loading) {
        return <div className="">Loading ...</div>;
    }
    const onFinish = async (values) => {
        console.log("Finish");
        const data = {
            phone: decodedId?.phone,
            newPassword: values?.newPassword,
            newPasswordConfirm: values?.newPasswordConfirm,
        };
        if (data.newPassword !== data.newPasswordConfirm) {
            message.warning("Mật khẩu không trùng khớp");
            return;
        } else if (data.newPassword.length < 6) {
            message.warning("Mật khẩu phải dài hơn 6 ký tự");
            return;
        }

        try {
            const res = await axios.post(`/changepassword/${id}`, data);
            console.log(res, "res");
            if (res.status === 200) {
                message.success("Đã đổi mật khẩu thành công");
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            } else {
                message.warning("Đã có lỗi xảy ra khi đổi mật khẩu");
            }
        } catch (error) {
            console.log(error);
            message.error("Đã có lỗi xảy ra");
        }
    };
    return (
        <div>
            <>
                <Navbar />
                <div className="register-section flex-center flex flex-col">
                    <div className="register-container flex-center">
                        <h2 className="form-heading">CẬP NHẬT MẬT KHẨU</h2>
                    </div>

                    <div className="w-full lg:w-[500px]">
                        <Form onFinish={onFinish}>
                            <h5>Nhập mật khẩu mới</h5>
                            <Form.Item name="newPassword">
                                <Input.Password placeholder="Nhập mật khẩu mới" />
                            </Form.Item>

                            <h5>Nhập xác nhận mật khẩu mới</h5>
                            <Form.Item
                                name="newPasswordConfirm"
                                placeholder="Nhập xác nhận mật khẩu mới">
                                <Input.Password placeholder="Nhập xác nhận mật khẩu mới" />
                            </Form.Item>

                            <Form.Item>
                                <Button className="w-full" type="primary" htmlType="submit">
                                    Cập nhật mật khẩu
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </>
        </div>
    );
};

export default PasswordRecoveryDetail;
