import React, { useEffect, useState, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/navbar.css";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/reducers/rootSlice";
import { FiMenu } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import jwt_decode from "jwt-decode";
import HeaderLogo from "../images/header_logo.png";

// Hàm kiểm tra hạn sử dụng của token
const isTokenExpired = (token) => {
    try {
        const decodedToken = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error("Token không hợp lệ");
        return true;
    }
};

const Navbar = () => {
    const [iconActive, setIconActive] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem("token") || "";
    const user = token ? jwt_decode(token) : {};

    // Hàm đăng xuất người dùng
    const logoutFunc = useCallback(() => {
        dispatch(setUserInfo({}));
        localStorage.removeItem("token");
        navigate("/login");
        window.location.reload();
    }, [dispatch, navigate]);

    // Kiểm tra token khi component được mount
    useEffect(() => {
        if (token && isTokenExpired(token)) {
            logoutFunc();
        }
    }, [token, logoutFunc]);

    return (
        <header>
            <nav className={iconActive ? "nav-active" : ""}>
                <h2 className="nav-logo">
                    <NavLink to={"/"}>
                        <img className="header_logo" src={HeaderLogo} alt={HeaderLogo}/>
                    </NavLink>
                </h2>
                <ul className="nav-links">
                    <li>
                        <NavLink to={"/"}>Trang chủ</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/facilities"}>Cơ sở y tế</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/services"}>Dịch vụ y tế</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/advise"}>Bác sĩ tư vấn</NavLink>
                    </li>
                    <li>
                        <NavLink to={"/news"}>Tin tức</NavLink>
                    </li>
                    {token && user.role && (
                        <li>
                            <NavLink to={"/dashboard/users"}>Quản lý cơ sở</NavLink>
                        </li>
                    )}

                    {!token ? (
                        <>
                            <li>
                                <NavLink className="btn" to={"/login"}>
                                    Đăng nhập
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="btn" to={"/register"}>
                                    Đăng ký
                                </NavLink>
                            </li>
                        </>
                    ) : (
                        <li className="user-menu">
                            <span className="user-info">{user.name}</span>
                            <span className="btn">Tài khoản</span>
                            <ul className="menu-dropdown">
                                <li>
                                    <NavLink className="nav-dropdown" to={"/profile"}>
                                        Thông tin
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-dropdown" to={"/record"}>
                                        Hồ sơ y tế
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-dropdown" to={"/appointments"}>
                                        Lịch hẹn
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-dropdown" onClick={logoutFunc}>
                                        Đăng xuất
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    )}
                </ul>
            </nav>
            <div className="menu-icons">
                {!iconActive && (
                    <FiMenu
                        className="menu-open"
                        onClick={() => {
                            setIconActive(true);
                        }}
                    />
                )}
                {iconActive && (
                    <RxCross1
                        className="menu-close"
                        onClick={() => {
                            setIconActive(false);
                        }}
                    />
                )}
            </div>
        </header>
    );
};

export default Navbar;
