import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/reducers/rootSlice";
import jwt_decode from "jwt-decode";
import fetchData from "../helper/apiCall";
import Navbar from "../components/Navbar";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

function Login() {
    const dispatch = useDispatch();
    const [formDetails, setFormDetails] = useState({
        phone: "",
        password: "",
    });
    const navigate = useNavigate();

    const inputChange = (e) => {
        const { name, value } = e.target;
        return setFormDetails({
            ...formDetails,
            [name]: value,
        });
    };

    const formSubmit = async (e) => {
        try {
            e.preventDefault();
            const { phone, password } = formDetails;
            if (!phone || !password) {
                return toast.error("Vui lòng nhập đầy đủ thông tin");
            } else if (password.length < 5) {
                return toast.error("Mật khẩu có độ dài từ 6 ký tự");
            }

            const { data } = await toast.promise(
                axios.post("/user/login", {
                    phone: formDetails.phone,
                    password: formDetails.password,
                }),
                {
                    pending: "Đang đăng nhập...",
                    loading: "Đang đăng nhập...",
                    success: "Đăng nhập thành công",
                    error: "Sai thông tin đăng nhập",
                }
            );
            localStorage.setItem("token", data.result.accessToken);
            dispatch(setUserInfo(jwt_decode(data.result.accessToken).id));
            getUser(jwt_decode(data.result.accessToken).id);
        } catch (error) {
            return error;
        }
    };

    const getUser = async (id) => {
        try {
            const temp = await fetchData(`/user/${id}`);

            dispatch(setUserInfo(temp));
            navigate("/");
            window.location.reload();
        } catch (error) {
            return error;
        }
    };

    return (
        <>
            <Navbar />
            <div className="register-section flex-center">
                <div className="register-container flex-center">
                    <h2 className="form-heading">ĐĂNG NHẬP</h2>
                    <form onSubmit={formSubmit} className="register-form">
                        <input
                            type="number"
                            name="phone"
                            className="form-input"
                            placeholder="Nhập số điện thoại"
                            value={formDetails.phone}
                            onChange={inputChange}
                        />
                        <input
                            type="password"
                            name="password"
                            className="form-input"
                            placeholder="Nhập mật khẩu"
                            value={formDetails.password}
                            onChange={inputChange}
                        />
                        <button type="submit" className="btn form-btn">
                            Đăng nhập
                        </button>
                    </form>
                    <div className="box_link">
                        <NavLink className="login-link" to={"/passwordrecovery"}>
                            Quên mật khẩu ?
                        </NavLink>
                    </div>
                    <p>
                        Chưa có tài khoản?{" "}
                        <NavLink className="login-link" to={"/register"}>
                            Đăng ký
                        </NavLink>
                    </p>
                </div>
            </div>
        </>
    );
}

export default Login;
