import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
import Navbar from "../components/Navbar";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

function Register() {
    // const [file, setFile] = useState("");
    // const [loading, setLoading] = useState(false);
    const [loading] = useState(false);
    const [formDetails, setFormDetails] = useState({
        phone: "",
        password: "",
        confpassword: "",
    });
    const navigate = useNavigate();

    const inputChange = (e) => {
        const { name, value } = e.target;
        return setFormDetails({
            ...formDetails,
            [name]: value,
        });
    };

    const formSubmit = async (e) => {
        e.preventDefault();
        const { phone, password, confpassword } = formDetails;

        try {
            if (!phone || !password || !confpassword) {
                return toast.error("Vui lòng nhập đầy đủ thông tin");
            } else if (password !== confpassword) {
                return toast.error("Mật khẩu không trùng khớp");
            } else if (password.length < 6) {
                return toast.error("Mật khẩu phải dài hơn 6 ký tự");
            }

            const res = await axios.post("/user/register", {
                phone: formDetails.phone,
                password: formDetails.password,
            });

            console.log(res, "res");
            const token = res?.data?.result;
            if (token) {
                toast.success("Đăng ký tài khoản thành công");
                navigate(`/otp/${token}`);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Lỗi đăng ký";
            toast.error(errorMessage);
        }
    };

    return (
        <>
            <Navbar />
            <div className="register-section flex-center">
                <div className="register-container flex-center">
                    <h2 className="form-heading">Đăng ký</h2>
                    <form onSubmit={formSubmit} className="register-form">
                        <input
                            type="text"
                            name="phone"
                            className="form-input"
                            placeholder="Số điện thoại"
                            value={formDetails.phone}
                            onChange={inputChange}
                        />
                        <input
                            type="password"
                            name="password"
                            className="form-input"
                            placeholder="Mật khẩu"
                            value={formDetails.password}
                            onChange={inputChange}
                        />
                        <input
                            type="password"
                            name="confpassword"
                            className="form-input"
                            placeholder="Xác nhận mật khẩu"
                            value={formDetails.confpassword}
                            onChange={inputChange}
                        />
                        <button
                            type="submit"
                            className="btn form-btn"
                            disabled={loading ? true : false}>
                            Đăng ký
                        </button>
                    </form>
                    <p>
                        Đã có tài khoản?{" "}
                        <NavLink className="login-link" to={"/login"}>
                            Đăng nhập
                        </NavLink>
                    </p>
                </div>
            </div>
        </>
    );
}

export default Register;
