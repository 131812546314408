import { Navigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

export const Protected = ({ children }) => {
    const token = localStorage.getItem("token");
    if (!token) {
        return <Navigate to={"/"} replace={true}></Navigate>;
    }
    return children;
};

export const Public = ({ children }) => {
    return children;
};

export const Manager = ({ children }) => {
    const user = jwtDecode(localStorage.getItem("token"));

    // if (user.role.includes("admin") || user.role.includes("manager")) {
    //     return children;
    // }
    return children;
    // return <Navigate to={"/"} replace={true}></Navigate>;
};

export const Admin = ({ children }) => {
    const user = jwtDecode(localStorage.getItem("token"));

    if (user.role) {
        return children;
    }
    return <Navigate to={"/"} replace={true}></Navigate>;
};
